import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Tooltip } from '../..'
import Badge, { BadgeType } from '../Badge/Badge'

export type GroupColorOptions = 'red' | 'pink' | 'teal' | 'blue' | 'green' | 'purple' | 'pine'

interface SubTitles {
  icon: string
  text: string
  additionalIcons?: string[]
}
export interface ICategoryCardProps {
  overview?: string
  linkTo?: string
  title: string
  index?: number
  subTitles: SubTitles[]
  onClick?: any
  isLoading?: boolean
  className?: string
  backgroundColor: string
  status?: {
    type: BadgeType
    text: string
  }
  headerInfo?: {
    icon: string
    text: string
  }
  showStatus?: boolean
}

// export const getColorClass = (groupColorClass: GroupColorOptions, selectedIndex: number = 0): string => {
//   let classes: string[] = []
//   switch (groupColorClass) {
//     case 'red':
//     default:
//       classes.push('category-one')
//       break
//     case 'pink':
//       classes.push('category-two')
//       break
//     case 'teal':
//       classes.push('category-three')
//       break
//     case 'blue':
//       classes.push('category-four')
//       break
//     case 'green':
//       classes.push('category-five')
//       break
//     case 'purple':
//       classes.push('category-six')
//       break
//     case 'pine':
//       classes.push('category-seven')
//       break
//   }

//   // if (selectedIndex % 5 === 0) {
//   //     // console.log("5", selectedIndex);
//   //     classes.push('s5');
//   // } else if (selectedIndex % 4 === 0) {
//   //     // console.log("4", selectedIndex);
//   //     classes.push('s4');
//   // } else
//   if (selectedIndex % 3 === 0) {
//     // console.log("3", selectedIndex);
//     classes.push('s3')
//   } else if (selectedIndex % 2 === 0) {
//     // console.log("2", selectedIndex);
//     classes.push('s2')
//   } else {
//     // console.log("1", selectedIndex);
//     classes.push('s1')
//   }

//   return classes.join(' ')
// }

export const CategoryCard: React.FC<ICategoryCardProps> = ({
  title,
  index,
  subTitles,
  onClick,
  isLoading,
  className,
  backgroundColor,
  status,
  headerInfo,
  linkTo,
  overview
}) => {
  // const getCardColor = (selectedIndex: number): string => {
  //   return getColorClass(groupColorClass, selectedIndex)
  // }

  const getClasses = () => {
    var classes: string[] = []

    if (isLoading) classes.push('loading')

    classes.push(className!)
    console.log(index)
    // if (index === undefined) {
    //   let tmpIndex = Math.floor(Math.random() * 3)
    //   classes.push(getCardColor(tmpIndex + 1))
    //   console.log(tmpIndex)
    // } else {
    //   classes.push(getCardColor(index + 1))
    // }

    return classes.join(' ')
  }

  const headerToolbar = (
    <Tooltip text={overview ? overview : ''} placement='auto'>
      <div className='ml-1 info-circle' style={{ width: '1rem' }}>
        <Icon icon='info-circle' type='light' />
      </div>
    </Tooltip>
  )

  const standardCard = (
    <div
      aria-busy={isLoading ?? false}
      style={{ height: linkTo ? '100%' : '', backgroundColor: backgroundColor }}
      className={`category-card card ${getClasses()}`}
      onClick={onClick}
    >
      {!!linkTo && !!overview && <div className='badge align-self-end text-light position-absolute'>{headerToolbar}</div>}
      {!!headerInfo && (
        <div className='badge align-self-start text-light position-absolute'>
          {!!headerInfo.icon && <div className={`fas fa-${headerInfo.icon} mr-1`}></div>}
          {headerInfo.text}
        </div>
      )}
      {!!status && <Badge content={status.text} type={status.type || 'primary'} />}

      <div className='category-card-content'>
        <h4>{title}</h4>
        {subTitles.map((row) => (
          <div className='mb-1'>
            {!!row.icon && <div className={`fas fa-${row.icon} mr-1`}></div>}
            {!!row.additionalIcons && row.additionalIcons.map((icon) => <div className={`fas fa-${icon} mr-1`} />)}
            {row.text}
          </div>
        ))}
      </div>
    </div>
  )
  const linkedCard = (
    <Link to={linkTo ? linkTo : ''} style={{ textDecoration: 'none' }}>
      {standardCard}
    </Link>
  )

  return linkTo ? linkedCard : standardCard
}

CategoryCard.defaultProps = {
  subTitles: []
}
