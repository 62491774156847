import React from 'react';
import { AuthProvider } from '../authorization';
import { AppConfigData } from '../types';
import { Modal } from './Modal';
import { Button } from './HUIcomponents/Button';

interface SessionExpirationModalProps {
    appConfigData: AppConfigData;
    customBody?: string;
    color?: string
}

export const SessionExpirationModal: React.FC<SessionExpirationModalProps> = ({
    appConfigData,
    customBody,
    color = '#1976d2'
}) => {
    const customBodyHTML = customBody ?? '<p>Your session has expired. The application will reload and any progress may be lost.</p>';

    return (
        <Modal
            open={true}
            aria-labelledby="Session Expired Modal"
            aria-describedby="Session Expired Modal"
            header={
                <h2 id="modal-title" style={{ color: 'black', padding: '16px' }}>
                    Session has expired
                </h2>
            }
            footer={
                <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                    <Button
                        text="Ok"
                        customBackgroundColor={color}
                        onClick={() => {
                            localStorage.setItem('RefreshAuthentication', 'true');
                            const authProvider = new AuthProvider(appConfigData);
                            authProvider.refreshToken().catch(error => console.log("error after refresh token error", error));
                        }}
                    />
                </div>
            }
        >
            <div style={{ padding: '24px' }}>
                <div
                    id="modal-description"
                    dangerouslySetInnerHTML={{ __html: customBodyHTML }}
                />
            </div>
        </Modal>
    );
};
