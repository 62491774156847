import { AppConfig, AppConfigData } from '../types'

type StageReturn = 'develop' | 'release' | 'master'

export const timeout = async (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

export const getStage = (appConfig: AppConfig): StageReturn => appConfig.stage as StageReturn

export const getRedirectUri = (): {loginRedirect: string, logoutRedirect: string} => {
  return {
    loginRedirect: window.location.hostname !== 'localhost' ? `https://${window.location.host}` : `http://localhost:${window.location.port}`,
    logoutRedirect: window.location.hostname !== 'localhost' ? `https://${window.location.host}/logout` : `http://localhost:${window.location.port}/logout`,
  }
}

export const getConfigFromFile = (appConfigData: AppConfigData):AppConfig => {
  const { okta, amplify } = appConfigData;
  const { loginRedirect, logoutRedirect } = getRedirectUri();

  return {
    ...appConfigData,
    okta: {
      ...okta,
      redirectUri: loginRedirect,
      postLogoutRedirectUri: logoutRedirect,
    },
    amplify: {
      ...amplify,
      oauth: {
        ...amplify.oauth,
        redirectSignIn: loginRedirect,
        redirectSignOut: logoutRedirect,
      },
    },
  };
}
